/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-expressions */
/* eslint-disable prefer-destructuring */
import React from 'react';
import { connect } from 'react-redux';
import page from '@/layouts/page';
import Head from '@/componentWebs/head';
import cookiee from 'cookie';
import SearchDownload from '@/componentWebs/downloadvideo/SearchDownload';
// import Merge from '@/componentWebs/downloadvideo/Merge';
import { getResponsiveImage } from '@/componentWebs/ImageNew';
import { languages } from '@/utils/utils';

function Index(props) {
  const { dataSite, dataCategory, dataArticle, intl, locale } = props;
  const ogImage = dataSite?.icon?.file;
  let url = dataSite?.url;
  if (typeof window !== 'undefined') {
    url = window.location.href;
  }

  return (
    <React.Fragment>
      <Head
        dataSite={dataSite}
        title={dataSite?.sitesName}
        ogImage={ogImage}
        keywords={dataSite && dataSite.seoKeywords}
        description={dataSite && dataSite.seoDescriptions}
        url={url || ''}
      />
      <div id="main" className="main-block main-block_v2">
        {/* <Merge /> */}
        <SearchDownload {...props} />
        {/*    </div>/wrapper */}
        <div className="wrapper wrapper-after-output">
          <div className="site-info-tabs">
            <h2>{intl.formatMessage({ id: 'platform' })}</h2>
            <div>
              <nav className="main-supported-services">
                <ul className="supported-services_v3 supported-services important-services">
                  {dataCategory?.slice(1)?.map(i => (
                    <li key={i?.id}>
                      <a href={`/${locale}/${i?.url}`} className="main-favicon">
                        <img
                          className="lazyload"
                          src={getResponsiveImage(i?.image?.file, [50, 50, 50])}
                          alt=""
                        />
                        {i?.categoriesName}
                      </a>
                    </li>
                  ))}
                </ul>
              </nav>
              <div className="how-to-use-v2">
                {dataArticle?.list?.map(i => (
                  <section className="advantages" key={i?.id}>
                    <h2>{i?.title}</h2>
                    <div className="advantages__block">
                      <div className="advantages__block-text">
                        <div
                          className="ck-content"
                          dangerouslySetInnerHTML={{ __html: i?.description }}
                        />
                      </div>
                      {i?.images?.length > 0 && (
                        <div className="advantages__block-img">
                          <img
                            className="lazyload"
                            src={getResponsiveImage(i?.images?.[0]?.file, [250, 350, 400])}
                            alt=""
                          />
                        </div>
                      )}
                    </div>
                  </section>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

Index.getInitialProps = async ctx => {
  const { req, dispatch, query } = ctx;
  let cookies;
  if (req) {
    cookies = req.cookies;
  } else {
    const documentCookie = document.cookie;
    cookies = cookiee.parse(documentCookie);
  }

  if (query?.lang?.length >= 3 && query?.lang !== 'tmp' && query?.lang !== 'root') {
    ctx.res.writeHead(302, { Location: `/${cookies?.locale || 'vi'}/${query?.lang}` });
    ctx.res.end();
    return;
  }

  let dataCategory = [];
  let dataArticle = [];
  const locale =
    (query?.lang && languages?.find(i => i?.languagesCode === query?.lang)?.languagesCode) ||
    cookies?.locale ||
    'vi';
  const languagesId = languages?.find(i => i?.languagesCode === locale)?.id || '1';
  const queryCategoryHome = {
    filter: {
      status: '1',
      languagesId,
      sitesId: '154',
      isHome: true,
    },
    sort: ['orderBy', 'ASC'],
  };

  // Dispatch fetchAllChildrenCategory
  const fetchAllChildrenCategoryPromise = new Promise(resolve => {
    dispatch({
      type: 'webs/fetchAllChildrenCategory',
      payload: queryCategoryHome,
      callback: result => {
        resolve(result?.result?.list || []);
      },
    });
  });
  dataCategory = await fetchAllChildrenCategoryPromise;
  if (dataCategory.length > 0) {
    const queryArticleHome = {
      filter: {
        status: '1',
        categoriesId: dataCategory?.[0]?.id,
      },
      sort: ['dateCreated', 'ASC'],
    };
    await dispatch({
      type: 'webs/fetchListArticle',
      payload: queryArticleHome,
      callback: result => {
        dataArticle = result?.result || {};
      },
    });
  }
  if (query?.lang !== locale) {
    ctx.res.writeHead(302, { Location: `/${locale}` });
    ctx.res.end();
  }
  return { dataCategory, dataArticle };
};
export default connect(({ webs }) => ({
  webs,
}))(page(Index));
